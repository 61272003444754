<template>
  <div>
    <h4>&nbsp;&nbsp;<span v-text="name"></span></h4>
    <el-table :data="tableData">
      <el-table-column prop="project" label="项目"/>
      <el-table-column prop="number" width="60" label="件数"/>
      <el-table-column prop="task_num" width="60" label="单量"/>
      <el-table-column prop="upload_num" width="60" label="上传"/>
      <el-table-column width="90" label="操作">
        <template #default="scope">
          <div v-show="scope.row.upload_num < scope.row.task_num">
            <el-button type="primary" size="small" @click="clickUpload(scope.row)">上传</el-button>
          </div>
          <el-button type="success" size="small" @click="clickShow(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div v-show="price">
      <h4>&nbsp;&nbsp;金额合计：<span v-text="price"></span> </h4>
      <el-table :data="tableData">
        <el-table-column prop="project" label="项目"/>
        <el-table-column prop="number" label="件数"/>
        <el-table-column prop="task_num" label="单数"/>
        <el-table-column prop="price" label="单价"/>
        <el-table-column label="合计">
          <template #default="scope">
            <span v-text="parseInt(scope.row.price * scope.row.task_num * scope.row.number)"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-------- 弹出框表单 start ---------->
    <el-dialog
        v-model="formData.show"
        width="95%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="上传图片">

      <div v-text="formData.project" style="text-align: center;"></div>
      <div style="text-align: center;">
        需求数 <span v-text="formData.task_num"></span> &nbsp;&nbsp;
        上传数 <span v-text="formData.upload_num"></span>
      </div>
      <br>

      <el-upload
          multiple
          :limit="formData.task_num - formData.upload_num"
          v-model:file-list="formData.lists"
          list-type="picture-card"
          accept="image/jpeg,image/png,image/jpg"
          :http-request="uploadFile"
          @on-error="upLoadFileError"
      >
        上传图片
      </el-upload>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onUploadSubmit">提交图片</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog
        v-model="detail.show"
        width="90%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="详情">

      <el-form label-width="80px">
        <el-form-item label="项目名称">
          <el-input
              v-model="detail.data.project"
              readonly/>
        </el-form-item>
        <el-form-item label="单价">
          <el-input
              v-model="detail.data.price"
              readonly/>
        </el-form-item>
        <el-form-item label="每单数量">
          <el-input
              v-model="detail.data.number"
              readonly/>
        </el-form-item>
        <el-form-item label="需求单数">
          <el-input
              v-model="detail.data.task_num"
              readonly/>
        </el-form-item>
        <el-form-item label="购买链接">
          <div v-text="detail.data.link"></div>
        </el-form-item>
        <el-form-item label="操作">
          <el-button size="small" @click="copyText()">复制链接内容</el-button>
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDetailDialog">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

  </div>
</template>

<script>
import {apiGetWechatCheckTask, apiUploadWechatCheckTask, apiGetWechatCheckTaskInfo} from '@/api/shareWechatTaskApi'
  import {apiGetShareQiniuToken} from "@/api/commonApi"
  import randomstring from 'randomstring'
  import * as qiniu from 'qiniu-js'
  import copy from 'copy-to-clipboard'
  export default {
    name: 'share.wechat.check-task-index',
    data () {
      return {
        name: '',
        code: '',
        price: '',
        info: {},
        tableData: [],
        formData: {
          project: '',
          task_num: 0,
          upload_num: 0,
          show: false,
          lists: [],
          data: {
            task_id: 0,
            code: '',
            images: [],
          },
        },
        detail: {
          show: false,
          data: {}
        }
      }
    },
    created() {
      this.code = this.$route.params.code
      this.getWechatCheckTaskInfo()
    },
    methods: {
      // 获取核销任务
      getWechatCheckTask () {
        apiGetWechatCheckTask({code: this.code}).then(data => {
          this.tableData = data
          this.price = 0
          data.forEach((item) => {
            this.price += (item.price * item.number * item.task_num)
          })
        })
      },
      // 获取详情
      getWechatCheckTaskInfo() {
        apiGetWechatCheckTaskInfo(this.code).then(data => {
          this.name = data.name
          this.getWechatCheckTask()
        })
      },
      onUploadSubmit () {
        if (!this.formData.data.images.length) {
          this.$message.warning('请上传图片')
          return
        }

        apiUploadWechatCheckTask(this.formData.data).then(() => {
          this.formData.data.images = []
          this.formData.lists = []
          this.getWechatCheckTask()
          this.closeDialog()
          this.$message.success('提交成功')
        })

      },
      // 点击上传
      clickUpload (row) {
        this.formData.project = row.project
        this.formData.task_num = row.task_num
        this.formData.upload_num = row.upload_num
        this.formData.data.task_id = row.id
        this.formData.data.code = this.code
        this.showDialog()
      },
      // 点击编辑
      clickShow (row) {
        this.detail.data = row
        this.showDetailDialog()
      },
      upLoadFileError (error) {
        console.log(error)
      },
      // 图片上传
      uploadFile (file) {
        let ext = file.file.name.substr(file.file.name.lastIndexOf(".")+1)
        let name = randomstring.generate(16)
        let path = `check/share-${name}.${ext}`

        apiGetShareQiniuToken({path: path, code: this.code}).then(data => {
          const observable = qiniu.upload(file.file, path, data.token)

          observable.subscribe(null, (err) => {
            console.log(err)
          }, (success) => {
            this.formData.data.images.push(data.domain + success.key)
            file.onSuccess({
              name: name,
              url: data.domain + success.key
            })
          })
        })
      },
      copyText() {
        copy(this.detail.data.link, {
          format: 'text/plain',
        })
      },
      showDialog () {
        this.formData.show = true
      },
      closeDialog () {
        this.formData.show = false
      },
      showDetailDialog () {
        this.detail.show = true
      },
      closeDetailDialog () {
        this.detail.show = false
      },
    }
  }
</script>
<style scoped>
.avatar-uploader .avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 24px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  text-align: center;
}
</style>
